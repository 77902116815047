<script>
import moment from 'moment';
import Layout from "../layouts/main";
import appConfig from "@/app.config";
import ActivityService from '@/services/activity'
import UserService from '@/services/user';

moment.locale('pt-BR');

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "Dashboard",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      title: "Dashboard",
      showActivityModal: false,
      loaded: false,
      activity: {},
      stats: {
        invoicesTotal: 0,
        invoicesCount: 0,
        salesCanceledTotal: 0,
        salesCanceledCount: 0,
        salesTotal: 0,
        salesCount: 0,
        commissions: 0,
        goals: 0,
        goalsInvoices: 0,
        goalsPercentage: 0,
      },
      customer: {},
      goals: {
        series: [],
        chartOptions: {
          chart: {
            type: "donut"
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          legend: {
            show: false
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false
                },
                size: ""
              }
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 100
                }
              }
            }
          ],
          colors: ['#1c1798', "#f8f9fa"]
        }
      },
      salesHistory: {
        series: [
          {
            name: "Total de Vendas",
            data: []
          },
          {
            name: "Pedidos Cancelados",
            data: []
          },
        ],
        chartOptions: {
          chart: {
            stacked: true,
            toolbar: {
              show: false
            },
            zoom: {
              enabled: true
            }
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: "15%",
              endingShape: "rounded"
            }
          },
          dataLabels: {
            enabled: false
          },
          xaxis: {
            categories: [
             
            ]
          },
          colors: ["#1c1798", "#f46a6a", "#34c38f"],
          legend: {
            position: "bottom"
          },
          fill: {
            opacity: 1
          }
        }
      }
    };
  },
  methods: {
    saveActivity() {
      if (!this.activity.content) {
        return this.$toast.info('Informe o conteúdo da atividade');
      }

      ActivityService.create(this.activity).then(() => {
        this.$toast.open('Atividade criada com sucesso');
        this.showActivityModal = false;
      }).catch(() => {
        this.$toast.error('Não foi possível criar a atividade. Tente novamente.')
      })
    }
  },
  mounted() {
    UserService.getStats().then(res => {
      this.stats = res.data;
      const seriesTotal = [];
      const seriesCanceled = [];
      const seriesLabels = [];

      this.loaded = true;

      this.stats.sales.forEach(sale => {
        seriesTotal.unshift(sale.totalValid.toFixed(2));
        seriesCanceled.unshift(sale.totalCanceled.toFixed(2));
        seriesLabels.unshift(moment(sale.date).format('MMM/YY'));
      });

      this.salesHistory.series = [
        { name: 'Total de Vendas', data: seriesTotal },
        { name: 'Pedidos Cancelados', data: seriesCanceled }
      ]

      this.salesHistory.chartOptions = { 
        chart: {
          stacked: true,
          toolbar: {
            show: false
          },
          zoom: {
            enabled: true
          }
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: "15%",
            endingShape: "rounded"
          }
        },
        dataLabels: {
          enabled: false
        },
        xaxis: {
          categories: seriesLabels
        },
        colors: ["#1c1798", "#f46a6a", "#34c38f"],
        legend: {
          position: "bottom"
        },
        fill: {
          opacity: 1
        }
      }

      this.goals = {
        series: [this.stats.goalsInvoices, (this.stats.goals - this.stats.goalsInvoices)],
        chartOptions: {
          chart: {
            type: "donut"
          },
          dataLabels: {
            enabled: false
          },
          tooltip: {
            enabled: false
          },
          legend: {
            show: false
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: false
                },
                size: '60%'
              }
            }
          },
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 100
                }
              }
            }
          ],
          colors: ['#1c1798', "#f8f9fa"]
        }
      }
    })

    const newActivity = this.$store.getters.newActivity;

    if (newActivity.customerId) {
      this.showActivityModal = true;
      this.customer = {
        _id: newActivity.customerId,
        name: newActivity.customerName,
      }

      this.activity = {
        type: 'phone',
        customer: this.customer._id,
        content: ''
      };

      this.$store.dispatch('NEW_ACTIVITY', {});
    }
  },
};
</script>

<template>
  <Layout>
    <!-- start page title -->
    <div class="row">
      <div class="col-12">
        <div class="page-title-box d-flex align-items-center justify-content-between">
          <h4 class="mb-0 font-size-18">Dashboard</h4>
        </div>
      </div>
    </div>
    <!-- end page title -->

    <div  v-if="loaded">
      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-sm mr-3">
                  <span
                    class="avatar-title rounded-circle bg-soft-primary text-primary font-size-24"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <h5 class="font-size-14 mb-0">PEDIDOS CONFIRMADOS</h5>
              </div>
              <div class="text-muted mt-2">
                <h4 class="mb-0">
                  {{stats.salesTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
                  <!-- <i class="mdi mdi-chevron-up ml-1 text-success"></i> -->
                </h4>
                <div class="d-flex">
                  <!-- <span class="badge badge-soft-success font-size-12">+ 0.2%</span> -->
                  <span class="text-truncate">{{stats.salesCount}} pedidos</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-sm mr-3">
                  <span
                    class="avatar-title rounded-circle bg-soft-danger text-danger font-size-24"
                  >
                    <i class="bx bx-cart"></i>
                  </span>
                </div>
                <h5 class="font-size-14 mb-0">PEDIDOS CANCELADOS</h5>
              </div>
              <div class="text-muted mt-2">
                <h4 class="mb-0">
                  {{stats.salesCanceledTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
                  <!-- <i class="mdi mdi-chevron-up ml-1 text-success"></i> -->
                </h4>
                <div class="d-flex mt-0">
                  <!-- <span class="badge badge-soft-success font-size-12">+ 0.2%</span> -->
                  <span class="text-truncate">{{stats.salesCanceledCount}} pedidos</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-sm mr-3">
                  <span
                    class="avatar-title rounded-circle bg-soft-success text-success font-size-24"
                  >
                    <i class="bx bx-dollar"></i>
                  </span>
                </div>
                <h5 class="font-size-14 mb-0">FATURAMENTO</h5>
              </div>
              <div class="text-muted mt-2">
                <h4 class="mb-0">
                  {{stats.invoicesTotal.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
                  <!-- <i class="mdi mdi-chevron-up ml-1 text-success"></i> -->
                </h4>
                <div class="d-flex">
                  <!-- <span class="badge badge-soft-success font-size-12">+ 0.2%</span> -->
                  <span class="text-truncate">{{stats.invoicesCount}} notas</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <div class="card">
            <div class="card-body">
              <div class="d-flex align-items-center mb-3">
                <div class="avatar-sm mr-3">
                  <span
                    class="avatar-title rounded-circle bg-soft-warning text-warning font-size-24"
                  >
                    <i class="bx bx-money"></i>
                  </span>
                </div>
                <h5 class="font-size-14 mb-0">COMISSÃO</h5>
              </div>
              <div class="text-muted mt-2">
                <h4>
                  {{stats.commissions.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}
                  <!-- <i class="mdi mdi-chevron-up ml-1 text-success"></i> -->
                </h4>
                <!-- <div class="d-flex">
                  <span class="badge badge-soft-success font-size-12">+ 0.2%</span>
                  <span class="text-truncate ml-2">comparado com período anterior

                  </span>
                </div> -->
              </div>
            </div>
          </div>

          <div class="card">
            <div class="card-body pb-0">
              <div class="clearfix">
                <h4 class="card-title mb-0">Metas</h4>
              </div>

              <div class="table-responsive mt-4 mb-0">
                <table class="table table-centered">
                  <tbody>
                    <tr>
                      <td class="pl-0 pt-0">
                        <p class="text-muted mb-0">Valor da Meta</p>
                        <h4 class="mb-1">{{stats.goals.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</h4>

                        <p class="text-muted mb-0 mt-4">Total de Vendas</p>
                        <h4 class="mb-1">{{stats.goalsInvoices.toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' })}}</h4>
                      </td>

                      <td class="text-center pt-0">
                        <apexchart
                          class="apex-charts"
                          dir="ltr"
                          height="100"
                          width="100"
                          :series="goals.series"
                          :options="goals.chartOptions"
                        ></apexchart>

                        <h6 class="mb-0">{{stats.goalsPercentage.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}}</h6>
                        <p class="text-muted mb-1">Atingido</p>
                      </td>
                      <!-- <td>
                        <h5 class="mb-0">{{stats.goalsPercentage.toLocaleString('pt-BR', { style: 'decimal', minimumFractionDigits: 2, maximumFractionDigits: 2 }) + '%'}}</h5>
                        <p class="text-muted mb-1">Atingido</p>
                      </td> -->
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div class="col-sm-8">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title mb-2">Histórico de Vendas</h4>

              <apexchart
                class="apex-charts"
                type="bar"
                dir="ltr"
                height="284"
                :series="salesHistory.series"
                :options="salesHistory.chartOptions"
              ></apexchart>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-modal
      v-model="showActivityModal"
      title="Nova Atividade"
      title-class="text-black font-18"
      body-class="p-3"
      hide-footer
    >
      <form @submit.prevent="saveActivity">
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="name">Cliente</label>
              <input
                id="name"
                v-model="customer.name"
                type="text"
                class="form-control"
                disabled
              />
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Tipo</label>
              <select
                v-model="activity.type"
                class="form-control"
                name="activityType"
              >
                <option value="phone">Telefone</option>
                <option value="visit">Visita</option>
                <option value="email">E-mail</option>
                <option value="whatsapp">Whatsapp</option>
              </select>
            </div>
          </div>
          <div class="col-12">
            <div class="form-group">
              <label class="control-label">Conteúdo</label>
              <textarea
                v-model="activity.content"
                class="form-control"
                name="content"
                rows="3"
              ></textarea>
            </div>
          </div>          
        </div>

        <div class="text-right pt-5 mt-3">
          <b-button variant="link" @click="showActivityModal = false">Cancelar</b-button>
          <b-button type="submit" variant="primary" class="ml-2">Criar Atividade</b-button>
        </div>
      </form>
    </b-modal>
  </Layout>
</template>

<style lang="scss">
  .table-centered td {
    border: none;
  }
</style>